import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../auth/auth.service';
import { ApiService } from '../../../core/services/api.service';
import { UtilsService } from '../../../core/services/utils.service';
import { ResizeService } from '../../../core/services/resize.service';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrl: './header-admin.component.scss'
})
export class HeaderAdminComponent {

  icons = { faUser, faRightFromBracket };
  profile: any;
  isMobileView = this.resize.isMobileView();
  isNavbarCollapsed: boolean = true;
  isAuthenticated: boolean = false;
  role: string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private api: ApiService,
    private resize: ResizeService,
  ) {}

  ngOnInit() {
    this.resize.resize$.subscribe((isMobile: boolean) => {
      this.isMobileView = isMobile;
    });

    this.auth.isAuthenticated$.subscribe({
      next: (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.auth.theRole$.subscribe({
            next: (role: string) => {
              this.role = role;
              this.getManagementProfile();
            },
            error: (error: any) => {
              console.error('Error in header component:', error);
            }
          });
        }
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });

    
  }


  getManagementProfile() {
    this.api.getManagementProfile().subscribe({
      next: (response: any) => {
        this.profile = response.data;
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    })
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  logout() {
    this.auth.logout();
    this.profile = null;
    this.router.navigate(['/home']);
    this.toggleNavbar();
  }
}
